import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import PageHeading from '../components/pageheading';
import MarketAreas from '../components/marketareas';

// markup
const ServicesPage = () => {
  return (
    <Layout>
        <Helmet>
            <title>Delfi.se - Våra tjänster</title>
        </Helmet>
        <article>
            <PageHeading heading="Tjänster" 
                preamble="Vi erbjuder ett brett spektrum av verktyg och tjänster, alla med målet att skapa insikter och effektivisera din marknadsföring och försäljning." 
                modifiers={{
                    'page-heading--small-padding': true,
                    'page-heading--centered': true
                }}/>
            <MarketAreas modifiers={{
                'market-areas--small-padding': true
            }} />
        </article>
    </Layout>   
  );
}

export default ServicesPage