import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import img1 from '../../images/map@2x.png';
import img2 from '../../images/statistics@2x.png';
import img3 from '../../images/dabas@2x.png';
import img4 from '../../images/analysis-and-insights-2x.png';
import './styles.scss';

const MarketAreas = props => {
    return (
        <section className={classNames({
            'market-areas': true,
            ...props.modifiers
        })}>
            {props.heading &&
                <header className="market-areas__header">
                    <h2 className="market-areas__heading">{props.heading}</h2>
                </header>
            }
            
            <div className="market-areas__col-container">
                <div className="market-areas__col">
                    <div className="market-areas__area-img">
                        <img src={img1} alt="" />
                    </div>
                    <h2 className="market-areas__area-heading">Butiksregister & kartverktyg</h2>
                    <p className="market-areas__area-text">
                        Uppnå högre effektivitet och bättre resultat med våra butiksregister och kartverktyg.
                    </p>
                    <Link className="market-areas__area-link" to="/services/salesdistricts">Läs mer</Link>
                </div>
                <div className="market-areas__col">
                    <div className="market-areas__area-img">
                        <img src={img2} alt="" />
                    </div>
                    <h2 className="market-areas__area-heading">Branschstatistik</h2>
                    <p className="market-areas__area-text">
                        Hur förändras totalmarknaden för dina produkter? Vilka segment tar andelar och vilka backar? Vilka förändringar är tillfälliga och vilka bildar ett mönster?
                    </p>
                    <Link className="market-areas__area-link" to="/services/statistics">Läs mer</Link>
                </div>
                <div className="market-areas__col">
                    <div className="market-areas__area-img">
                        <img src={img3} alt="" />
                    </div>
                    <h2 className="market-areas__area-heading">Produkt- och artikeldatabas</h2>
                    <p className="market-areas__area-text">
                        Dabas är vår artikeldatabas som innehåller information om de livsmedelsprodukter som finns på den svenska foodservicemarknaden.
                    </p>
                    <Link className="market-areas__area-link" to="/services/dabas">Läs mer</Link>
                </div>
                <div className="market-areas__col">
                    <div className="market-areas__area-img">
                        <img src={img4} alt="" />
                    </div>
                    <h2 className="market-areas__area-heading">Analyser & Insikter</h2>
                    <p className="market-areas__area-text">
                        Värdefull kunskap om utvecklingen på den svenska foodservice- och dagligvarumarknaden för beslutsfattare.
                    </p>
                    <Link className="market-areas__area-link" to="/publications">Läs mer</Link>
                </div>
            </div>
        </section>
    );
};

export default MarketAreas;